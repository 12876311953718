import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const WieZijnWijPage = ({ data }) => (
  <Layout>
    <SEO
      title="Wie Zijn Wij"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <h2 className="employees__header">Even voorstellen</h2>
    <div className="employees container-fluid">
      <div className="row">
        {data.medewerkers.edges.map(medewerker => (
          <div
            key={medewerker.node.id}
            className="employee__card col-xs-12 col-lg-6 col-xl-3"
          >
            <div className="card">
              <Img
                fluid={medewerker.node.foto.fluid}
                alt={medewerker.node.naam}
                className="card-img-top employees__picture"
              />
              <div className="card-body">
                <h5 className="card-title">{medewerker.node.naam}</h5>
                <hr />
                <p className="card-text">
                  {medewerker.node.omschrijving.omschrijving}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    medewerkers: allContentfulMedewerkers {
      edges {
        node {
          id
          naam
          omschrijving {
            omschrijving
          }
          foto {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
export default WieZijnWijPage
